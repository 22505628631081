<template>
    <div>
        <ts-page-title
            :title="$t('cashCollectionByCashier.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('cashCollectionByCashier.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-loading-banner :loading="loading">
                <ts-panel-wrapper>
                    <div class="tw-flex tw-space-x-4 tw-items-start">
                        <div class="tw-space-y-2" style="width: 230px">
                            <label class="required">{{
                                $t("cashCollectionByCashier.dateRange")
                            }}</label>
                            <a-range-picker
                                v-model="model.date_range"
                                :allowClear="false"
                                format="DD-MM-YYYY"
                                valueFormat="DD-MM-YYYY"
                                style="width: 100%"
                            />
                        </div>
                        <div class="tw-space-y-2" style="width: 230px">
                            <label>{{
                                $t("cashCollectionByCashier.cashierName")
                            }}</label>
                            <a-select
                                show-search
                                v-model="model.casher_id"
                                mode="multiple"
                                style="width: 100%"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                :placeholder="$t('assignDriver.typeToSearch')"
                                @search="handleCashierSearch"
                            >
                                <a-select-option
                                    v-for="c in cashiers"
                                    :key="c.user_id"
                                >
                                    {{ c.user_name }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="tw-space-y-2" style="width: 230px">
                            <label class="required">{{
                                $t("cashCollectionByCashier.type")
                            }}</label>
                            <br />
                            <a-radio-group
                                v-model="model.report_type"
                                :default-value="1"
                                button-style="solid"
                                @change="fetchData"
                            >
                                <a-radio-button :value="1">
                                    {{ $t("cashCollectionByCashier.detail") }}
                                </a-radio-button>
                                <a-radio-button :value="2">
                                    {{ $t("cashCollectionByCashier.summary") }}
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <ts-button
                            class="tw-mt-5"
                            color="primary"
                            @click.prevent="fetchData"
                            >{{ $t("preview") }}</ts-button
                        >
                        <ts-button
                            class="tw-mt-5"
                            outline
                            color="info"
                            @click.prevent="pdf"
                            >{{ $t("pdf") }}</ts-button
                        >
                    </div>
                </ts-panel-wrapper>
                <hr />
                <ts-panel-wrapper>
                    <CashCollectComponentDetail
                        :resources="resources"
                        v-if="model.report_type == 1"
                    />
                    <CashCollectComponentSummary
                        v-if="model.report_type == 2"
                        :resources="resources"
                    />
                </ts-panel-wrapper>
            </ts-loading-banner>
        </ts-panel>

        <!-- preview pdf -->
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import CashCollectComponentDetail from "./components/cash-collection-by-cashier-detail.vue";
import CashCollectComponentSummary from "./components/cash-collection-by-cashier-summary.vue";
import { mapActions } from "vuex";
import { debounce } from "lodash";
export default {
    name: "cashCollectionByCashier",
    components: {
        CashCollectComponentDetail,
        CashCollectComponentSummary
    },
    data() {
        return {
            loading: false,
            preview_pdf: false,
            waiting: false,
            src: "",
            cashiers: [],
            model: {
                date_range: [
                    moment().format("DD-MM-YYYY"),
                    moment().format("DD-MM-YYYY")
                ],
                casher_id: undefined,
                report_type: 1
            },
            resources: []
        };
    },
    methods: {
        ...mapActions("report/cashCollectionByCashier", ["fetch", "toPdf"]),
        ...mapActions("report/formViewModel", ["fetchFormView"]),
        fetchData() {
            this.loading = true;
            this.resources = [];
            this.fetch(this.model)
                .then(response => {
                    this.resources = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCashierSearch: debounce(function(value) {
            this.loading = true;
            this.fetchFormView({
                fnName: "user",
                search_user: value
            })
                .then(response => {
                    this.cashiers = response.data["user"];
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }, 500),
        pdf() {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(this.model)
                .then(response => {
                    this.src = response.url;
                })
                .catch(() => {
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    }
};
</script>
