<template>
    <div style="min-height: 300px; max-height: 70vh" class="tw-overflow-scroll">
        <table class="tw-w-full table">
            <thead>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-text-center"
                    >
                        No
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Transaction Date
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Booking #
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Delivery #
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Receiver Phone
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Receiver Location
                    </th>
                    <!-- <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        FX Rate
                    </th> -->
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Cash On Delivery
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Clear In USD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Clear In KHR
                    </th>
                </tr>
            </thead>
            <tbody v-if="resources.group != undefined">
                <template v-for="(dateItem, indexDate) in resources.group">
                    <tr class="tw-bg-gray-200" :key="indexDate">
                        <td
                            class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            colspan="9"
                        >
                            <b>Received Date:</b> {{ indexDate }}
                        </td>
                    </tr>
                    <template v-for="(cashierItem, cashier) in dateItem">
                        <tr class="tw-bg-gray-200" :key="indexDate + cashier">
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                colspan="9"
                            >
                                <b>Cashier Name:</b> {{ cashier }}
                            </td>
                        </tr>
                        <template v-for="(voucherItem, voucher) in cashierItem">
                            <tr
                                class="tw-bg-gray-200"
                                :key="voucher + '_' + indexDate + cashier"
                            >
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                    colspan="3"
                                >
                                    <b>Driver Name:</b>
                                    {{ voucherItem[0]['driver_name'] }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                    colspan="2"
                                >
                                    <b>Voucher No:</b> {{ voucher }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <b>Total:</b>
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="
                                            sumByColumn(
                                                voucherItem,
                                                'amount_tobe_collected'
                                            )
                                        "
                                        class="tw-font-extrabold tw-text-blue-600"
                                    ></ts-accounting-format>
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="
                                            sumByColumn(
                                                voucherItem,
                                                'amount_tobe_cleared_usd'
                                            )
                                        "
                                        class="tw-font-extrabold tw-text-blue-600"
                                    ></ts-accounting-format>
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="0"
                                        currency="KHR"
                                        :value="
                                            sumByColumn(
                                                voucherItem,
                                                'amount_tobe_cleared_khr'
                                            )
                                        "
                                        class="tw-font-extrabold tw-text-blue-600"
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                            <tr
                                v-for="(v, index) in voucherItem"
                                :key="index + v.delivery_number"
                            >
                                <td
                                    class="tw-border-b tw-text-center tw-px-3 tw-py-1"
                                >
                                    {{ index + 1 }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.transaction_date }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.booking_number }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.delivery_number }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.receiver_phone }}
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.receiver_location }}
                                </td>
                                <!-- <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    {{ v.fx_rate }}
                                </td> -->
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="v.amount_tobe_collected"
                                    ></ts-accounting-format>
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="v.amount_tobe_cleared_usd"
                                    ></ts-accounting-format>
                                </td>
                                <td
                                    class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                >
                                    <ts-accounting-format
                                        :digit="0"
                                        currency="KHR"
                                        :value="v.amount_tobe_cleared_khr"
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <tr :key="indexDate + '__'">
                        <td height="20" colspan="9"></td>
                    </tr>
                </template>
            </tbody>
            <tfoot v-if="resources.amount_group != undefined">
                <tr>
                    <td height="20" colspan="9"></td>
                </tr>
                <tr class="tw-bg-gray-200">
                    <td
                        class="tw-border-b tw-text-right tw-px-3 tw-py-1"
                        colspan="6"
                    >
                        <b class="tw-uppercase">Grand Total: </b>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="2"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_collected
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="2"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_cleared_usd
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="0"
                            currency="KHR"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_cleared_khr
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { sumBy } from 'lodash'
export default {
    name: 'cashCollectComponent',
    props: ['resources'],
    methods: {
        sumByColumn (resources, column) {
            return sumBy(resources, column)
        }
    }
}
</script>

<style lang="css" scoped>
::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(161, 3, 3);
}
.table > thead > tr > th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(59, 130, 246);
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.table > tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
}
</style>
