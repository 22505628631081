var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-overflow-scroll",staticStyle:{"min-height":"300px","max-height":"70vh"}},[_c('table',{staticClass:"tw-w-full table"},[_vm._m(0),(_vm.resources.group != undefined)?_c('tbody',[_vm._l((_vm.resources.group),function(dateItem,indexDate){return [_c('tr',{key:indexDate,staticClass:"tw-bg-gray-200"},[_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"9"}},[_c('b',[_vm._v("Received Date:")]),_vm._v(" "+_vm._s(indexDate)+" ")])]),_vm._l((dateItem),function(cashierItem,cashier){return [_c('tr',{key:indexDate + cashier,staticClass:"tw-bg-gray-200"},[_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"9"}},[_c('b',[_vm._v("Cashier Name:")]),_vm._v(" "+_vm._s(cashier)+" ")])]),_vm._l((cashierItem),function(voucherItem,voucher){return [_c('tr',{key:voucher + '_' + indexDate + cashier,staticClass:"tw-bg-gray-200"},[_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"3"}},[_c('b',[_vm._v("Driver Name:")]),_vm._v(" "+_vm._s(voucherItem[0]['driver_name'])+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Voucher No:")]),_vm._v(" "+_vm._s(voucher)+" ")]),_vm._m(1,true),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":2,"value":_vm.sumByColumn(
                                            voucherItem,
                                            'amount_tobe_collected'
                                        )}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":2,"value":_vm.sumByColumn(
                                            voucherItem,
                                            'amount_tobe_cleared_usd'
                                        )}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":0,"currency":"KHR","value":_vm.sumByColumn(
                                            voucherItem,
                                            'amount_tobe_cleared_khr'
                                        )}})],1)]),_vm._l((voucherItem),function(v,index){return _c('tr',{key:index + v.delivery_number},[_c('td',{staticClass:"tw-border-b tw-text-center tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(v.transaction_date)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(v.booking_number)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(v.delivery_number)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(v.receiver_phone)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(v.receiver_location)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":v.amount_tobe_collected}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":v.amount_tobe_cleared_usd}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":0,"currency":"KHR","value":v.amount_tobe_cleared_khr}})],1)])})]})]}),_c('tr',{key:indexDate + '__'},[_c('td',{attrs:{"height":"20","colspan":"9"}})])]})],2):_vm._e(),(_vm.resources.amount_group != undefined)?_c('tfoot',[_vm._m(2),_c('tr',{staticClass:"tw-bg-gray-200"},[_vm._m(3),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":2,"value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_collected
                                : 0}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":2,"value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_cleared_usd
                                : 0}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":0,"currency":"KHR","value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_cleared_khr
                                : 0}})],1)])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-text-center"},[_vm._v(" No ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Transaction Date ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Booking # ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Delivery # ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Receiver Phone ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Receiver Location ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Cash On Delivery ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Clear In USD ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Clear In KHR ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('b',[_vm._v("Total:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"height":"20","colspan":"9"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"tw-border-b tw-text-right tw-px-3 tw-py-1",attrs:{"colspan":"6"}},[_c('b',{staticClass:"tw-uppercase"},[_vm._v("Grand Total: ")])])}]

export { render, staticRenderFns }